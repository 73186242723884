import { React, styled, ImageYo } from "../shipityo/index";
import Layout from "../layouts/index";
import Img from "gatsby-image";
//import Layout from "../layouts/index";
//import Banners from "../components/banners"

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    h2 {
        font-weight: normal;
        text-align: center;
        #proven{
            font-size: 4em;
            @media only screen 
            and (max-width: 650px)  {
                font-size: 2.5em;
            } 
        }
        #professional{
            @media only screen 
            and (max-width: 650px)  {
                font-size: 1em;
            } 
        }
        #contact{
            font-size: 2em;
            @media only screen 
            and (max-width: 650px)  {
                font-size: 1em;
            } 
        }
    }
    p{
        font-size: 1.4em;
        text-align: center;
        @media only screen 
            and (max-width: 650px)  {
                font-size: 1em;
        } 
    }
    #home-images{
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        div {
            flex-basis: 40%;
            max-height: 250px;
            max-width: 400px;
            margin: 5px;
            img{
                width: 100%;
            }
        }
        @media only screen 
        and (max-width: 650px)  {
            flex-direction: column;
            justify-content: space-around;
        } 
    }
`;

class Component extends React.Component {

    constructor({data}) {
        let  homeImages =[];
        super();
        this.state = {
        };
        this.render = this.render.bind(this);
        if(data){
            if(data.allFile){
                if(data.allFile.edges){
                    homeImages = data.allFile.edges
                    this.state = {homeImages: homeImages};
                } 
            } 
        }
    }

    // <Banners layoutData={this.props}/>
    // 
  
    render() {
        return (
            <Layout>
                <ImageYo src="banner1.jpg"></ImageYo>
                <Container>
                    <h2>
                        <span id="professional">Ethical - Professional - Excellent</span><br/>
                        <span id="proven">AT YOUR SERVICE</span><br/>
                        <span id="contact">082 358 8065</span>
                    </h2>
                    <p>
                        We are a law firm that is committed to rendering excellent legal service which builds a trustworthy relationship with our clients. We are dedicated to deliver on our mandate with enthusiasm, respect and integrity. Our attorneys  are conscious of how daunting it may be for our clients to be involved in litigation and it is therefore our duty to not only provide high quality professional legal service; but more importantly to take our client by the hand throughout the entire process. 
                    </p>
                    
                    <p>
                        Loggenberg Attorneys Incorporated is a law firm that partners with its own clients to plan and implement customized legal solutions. We understand that listening to our clients and involving them in every step brings about high level of client satisfaction and serves the best interest of the client.
                    </p>
                    <p>
                        Our knowledge in various practice arears; our commitment to affordable service and our authentic attention to our client’s interest make us the go to law firm for civil litigation.
                    </p>
                    <div id="home-images">
                        {this.state.homeImages.map((image, index) => (
                            (index !== 3) ? <div key={index}><Img key={index} sizes={image.node.childImageSharp.sizes} /></div> : <span key={index}></span>
                        ))}
                    </div>
                </Container>
            </Layout>
        ) 
    }
  }


  export default Component;
  
  export const query = graphql`
    query IndexQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/home-images/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1900) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  `